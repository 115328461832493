.App {
  background-color: white;
}

#overlay {
  display: none; z-index: 99;
  width: 100%; height: 100%;
  position: absolute; top: 0; left: 0;
  background: rgba(0, 0, 0, 0.5);
}

#confirmOverlay {
  display: none; z-index: 99;
  width: 100%; height: 100%;
  position: absolute; top: 0; left: 0;
}

.confirmButton, .closeButton {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.gameVideo {
  margin-top: 50px;
  margin-right: 30px;
  margin-bottom: 20px;
  max-width: 100%;
  min-width: 400px;
  height: auto;
}

@media screen and (max-width: 550px) {
  .gameVideo {
    min-width: 200px;
  }
}

.AuthFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.TwoStepLoginContainer {
  display: none;
}

@media screen and (max-width: 600px) {
  .AuthFormContainer {
    width: 650px;
    flex-direction: column;
  }
  .loginRightDiv {
    position: initial !important;
  }
}

.qrCodeText {
  margin-top: 20px;
}

.TwoStepLogin-form-container {
  position: absolute;
  top: 40%;
  left: 30%;
}

.Auth-form {
  width: 720px;
  width: 600px;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: white;
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-img {
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
  margin-left: 80px;
}

.Auth-form-input {
  height: 100px;
  width: 300px;
  display: block;
}

.Auth-form-login {
  width: 500px;
  height: 100px;
  display: flex;
}

.loginRightDiv {
  display: flex;
  flex-direction: column;
  width: 100px;
  position: relative;
  bottom: 200px;
}

.loginBaseSelect {
  margin-top: 10px;
}

.selectedGameName {
  margin-top: 15px;
  font-size: 1.2em;
}

.btn-return-to-login {
  margin-top: 20px;
}

.btn-sendAuthEmail2 {
  margin-top: 20px;
  margin-right: 20px;
}

.btn-return-to-login2 {
  margin-left: 20px;
}

.btn-copyright {
  margin-bottom: 10px;
}

.btn-english {
  margin-left: 10px;
  margin-top: 20px;
}

.btn-finnish {
  margin-left: 10px;
  margin-top: 10px;
}

.btn-swedish {
  margin-left: 10px;
  margin-top: 10px;
}

.btn-login {
  height: 90px;
  margin-left: 30px;
}

.btn-sendAuthEmail {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .btn-login {
    margin-left: 20px;
  }
}

.btn-cancel {
  margin-left: 20px;
  background: red !important;
  margin-bottom: 20px;
}

.btn-add-game-cancel {
  margin-left: 20px;
  background: red !important;
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-cancelRecipe {
  margin-left: 20px;
  background: maroon !important;
  margin-bottom: 20px;
}

.btn-cancelAssignPros {
  background: red !important;
  margin-bottom: 20px;
}

.btn-cancelSearchResults {
  background: red !important;
  margin-bottom: 20px;
}

.btn-cancelTwoStepAuth {
  background: red !important;
  margin-top: 20px;
}

.btn-saveAssignPros {
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn-save {
  margin-bottom: 20px;
}

.btn-save2 {
  margin-left: 20px;
  margin-bottom: 20px;
}

.btn-save3 {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn-save4 {
  margin-bottom: 20px;
}

.btn-cancel:hover, .btn-add-game-cancel:hover {
  background: maroon !important;
}

.btn-add-game {
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn-add-game2 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.btn-reset-settings {
  margin-top: 20px;
}

.btn-remove {
  background: gray !important;
  margin-bottom: 20px;
}

.btn-remove:hover {
  background: silver !important;
}

.btn-game {
  margin-bottom: 20px;
}

.btn-get-templates {
  margin-bottom: 20px;
}

.btn-game-selected {
  background: maroon !important;
  margin-bottom: 20px;
}

.EditGamesButtons {
  height: 60px;
}

.ForgetEmail-reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin-left: 70px;
}

.ForgotEmail-input {
  width: 300px;
  margin-right: 20px;
}

.mt-3 {
  margin-left: 120px;
  width:200px;
}

.mt-4 {
  margin-left: 120px;
  width: 300px;
}

.underline {
  text-decoration: underline !important;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.Loading-icon {
  height: 100px;
}

.Loading-icon-div {
  position: absolute;
  top: 45%;
  left: 50%;
}

.Navbar {
  max-height: 100px;
}

.Navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(128, 153, 207);
}

.Client-Navbar-cognituner-logo-image, .Menu-Navbar-cognituner-logo-image {
  height: 45px;
  width: 281.43px;
}

@media screen and (max-width: 942px) {
  .Client-Navbar-cognituner-logo-image, .Menu-Navbar-cognituner-logo-image {
    height: 44px;
    margin-left: -50px;
  }
}

@media screen and (max-width: 890px) {
  .Client-Navbar-cognituner-logo-image, .Menu-Navbar-cognituner-logo-image {
    margin-left: -90px;
  }
}

@media screen and (max-width: 810px) {
  .Client-Navbar-cognituner-logo-image {
    margin-left: -150px;
  }
}

@media screen and (max-width: 770px) {
  .Client-Navbar-cognituner-logo-image, .Menu-Navbar-cognituner-logo-image {
    margin-left: -70px;
  }
}

@media screen and (max-width: 580px) {
  .Client-Navbar-cognituner-logo-image, .Menu-Navbar-cognituner-logo-image {
    margin-left: -50px;
  }
}

.Navbar-searchbar {
  margin-left: 0px;
  position: relative;
}

.Navbar-menu {
  padding-top: 2px;
}

.Menu-Navbar-cognituner-logo {
  margin-left: 80px;
}

.Client-Navbar-cognituner-logo {
  margin-left: 300px;
}

@media screen and (max-width: 770px) {
  .Client-Navbar-cognituner-logo {
    margin-left: 50px;
  }
  .Menu-Navbar-cognituner-logo {
    margin-left: 50px;
  }
}

.CreateOrEditClient-role-selection, .CreateOrEditClient-language-selection,
.EditOrDeleteAccount-language-selection, .CreateOrEditProUser-language-selection,
.CreateOrEditProUser-role-selection {
  width: 190px;
}

.Organisation-type-selection {
  width: 190px;
  margin-left: 20px;
}

.Dropdown-imagebutton {
  padding: 10px;
  margin-top: 4px;
}

.Dropdown-menu {
  display: inline-block;
}

.Dropdown-content {
  position: absolute;
  background-color: lightgrey;
  min-width: 200px;
  display: none;
  z-index: 1;
}

.Dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.Dropdown-content a:hover {
  background-color: orange;
}

.Dropdown-content button {
  color: black !important;
  padding: 12px 16px !important;
  text-decoration: none !important;
}

.Logout-menu {
  display: block !important;
  width: 250px !important;
  border-radius: 0px !important;
}

.Dropdown-content button:hover {
  background-color: orange !important;
}

.Dropdown-content input {
  display: block;
}

.Dropdown-menu:hover .Dropdown-content {
  display: block;
}

.Dropdown-menu:hover .Dropdown-imagebutton {
  background-color: grey;
}

.Google-logout-text {
  margin-left: 60px;
}

.ClientSelection-clientcount {
  margin-left: 50px;
  margin-bottom: 50px;
}

.ClientSelection-clientorg {
  margin-left: 50px;
}

.ClientSelection-clientorg2 {
  margin-left: 50px;
  margin-bottom: -20px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  display: block;
}

.proUserSelectName {
  cursor: pointer;
}

.ClientSelection-clientlist, .Organisation-orglist {
  list-style-type: none;
  line-height: 50%;
  display: inline-block;
  width: 200px;
  margin-top: 30px;
}

.Modules-list-link {
  color: black !important;
  text-decoration: none !important;
  padding: 0 !important;
  width: 250px !important;
  text-align: left !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  border-width: 0px !important;
  white-space: nowrap !important;
  background-color: transparent !important;
}

.category-container {
  display: flex;
  margin-top: 15px;
}

.category-module {
  position: relative;
  left: -20px;
  margin-left: 25px;
  width: 250px;
  display: inline-block;
}

.category-modules-list {
  list-style-type: none;
  width: 200px;
  margin-left: 10px;
  display: none;
}

.category-modules-list-selected {
  list-style-type: none;
  width: 200px;
  margin-left: 10px;
  display: block;
}

.yellow-line {
  display: inline-block;
}

.vertical-line-yellow {
  border-left: 20px solid rgb(255, 192, 0);
  border-radius: 5px;
  position: relative;
}

.vertical-line-yellow-selected {
  border-left: 20px solid rgb(255, 192, 0);
  border-radius: 5px;
  position: relative;
}

.vertical-line-red {
  border-left: 20px solid rgb(255, 0, 0);
  border-radius: 5px;
}

.vertical-line-blue {
  border-left: 20px solid rgb(0, 112, 192);
  border-radius: 5px;
}

.vertical-line-green {
  border-left: 20px solid rgb(0, 176, 80);
  border-radius: 5px;
}

.vertical-line-gray {
  border-left: 20px solid rgb(118, 113, 113);
  border-radius: 5px;
}

.usersList {
  list-style-type: disc;
  width: 100px;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li button {
  display: block !important;
  color: white !important;
  text-align: center !important;
  padding: 14px 16px !important;
}

.Username {
  padding-top: 5px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 200px !important;
  color: white !important;
}

@media screen and (max-width: 580px) {
  .Username {
    display: none !important;
  }
}

.Username-Menu {
  display: none !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 250px !important;
  color: black !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 580px) {
  .Username-Menu {
    display: block !important;
  }
}

.ClientName {
  padding-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  margin-left: 100px;
  color: white !important;
}

@media screen and (max-width: 1200px) {
  .ClientName {
    display: none;
  }
}

.ClientName-Menu {
  display: none !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 250px !important;
  border-radius: 0px !important;
}

@media screen and (max-width: 1200px) {
  .ClientName-Menu {
    display: block !important;
  }
}

.Clock {
  position: relative;
  top: 8px;
  display: none;
}

@media screen and (min-width: 1350px) {
  .Clock {
    display:inline-block;
  }
}

.Gauge, .Hourglass {
  display:none;
  padding-top: 10px;
}

@media screen and (min-width: 1280px) {
  .Hourglass {
    display: inline-block;
  }
}

@media screen and (min-width: 1264px) {
  .Gauge {
    display: inline-block;
  }
}

@media screen and (min-width: 1500px) {
  label, button, td, tr, th, strong, span, p, a {
     font-size: 1.15em !important;
  }
  .selectedGameName {
    font-size: 1.8em !important;
  }
  #professionsCheckBoxLabel, #animalsCheckBoxLabel, #plantsCheckBoxLabel,
  #citiesCheckBoxLabel, #maleNamesCheckBoxLabel, #additionCheckBoxLabel,
  #multiplicationCheckBoxLabel, #professionsCheckBoxLabel2, #plantsCheckBoxLabel2,
  #foodStuffCheckBoxLabel, #vehiclesCheckBoxLabel, #figureGeometricLabel,
  #figureNumberLabel, #positionLeftLabel, #positionMiddleLabel,
  #interferenceMathLabel {
    width: 150px !important;
  }
  #advancedFishLabel, #advancedFlagsLabel,
  #difficultyA1Label, #difficultyA2Label, #difficultyB1Label,
  #difficultyB2Label {
    width: 110px !important;
  }
  .fieldShapeDiv {
    width: 720px !important;
  }
  .fieldShapeRadioButtonGroup {
    width: 720px !important;
  }
  .fieldShapeRadioButton3Group, .fieldShapeRadioButton4Group,
  .fieldShapeRadioButton5Group {
    width: 650px !important;
  }
  .settings-container, .slider, .EditParameters_select {
    width: 500px !important;
  }
  .Menu-left {
    width: 550px !important;
  }
  .lockGamesDiv {
    width: 420px !important;
  }
  .addGameSpan2 {
    margin-left: 28px !important;
  }
  .addGameCheckMark {
    margin-top: 8px !important;
    margin-right: 10px !important;
    flex: 1 1 auto;
  }
  .coerd-row5-item1 {
     width: 12% !important;
  }
}

.Navbar-links {
  width: 100%;
}

.modules-label {
  margin-top: 15px;
}

.selected-modules-link a {
  color: green !important;
  font-weight: bold !important;
}

.selected-modules-link button {
  color: green !important;
  font-weight: bold !important;
}

.modules-exercise-link {
  margin-left: 15px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  text-align: left !important;
  color: black !important;
}

.mfad1-row1-item1 {
  width: 500px;
  display: flex;
  padding-top: 10px;
}

.ExerciseList-info-icon {
  margin-left: 20px;
}

#NavbarMenuSearchbar {
  background-color: rgb(242, 242, 242);
  background-image: url('./images/clients-searchbar-img.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  font-size: 16px;
  padding: 1px 25px 1px 5px;
  border: 1px solid rgb(128, 153, 207);
  margin-bottom: 12px;
  margin-top: 20px;
  outline: none;
  color: rgb(128, 153, 207);
  width: 200px;
  margin-left: 20px;
  margin-right: 20px;
  display: none;
}

@media screen and (max-width: 852px) {
  #NavbarMenuSearchbar {
    display: block;
  }
}

#Navbar-searchbar {
  background-color: rgb(128, 153, 207);
  background-image: url('./images/navbar-searchbar-img.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  width: 100%;
  font-size: 16px;
  padding: 1px 25px 1px 5px;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  margin-top: 20px;
  margin-left: 70px;
  outline: none;
  color: white;
  width: 200px;
}

@media screen and (max-width: 852px) {
  #Navbar-searchbar {
    display: none;
  }
}

#MenuSearchbar, #MenuSearchbar2 {
  background-color: rgb(242, 242, 242);
  background-image: url('./images/clients-searchbar-img.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 20px;
  font-size: 16px;
  padding: 1px 25px 1px 5px;
  border: 1px solid rgb(128, 153, 207);
  margin-bottom: 12px;
  margin-top: 20px;
  outline: none;
  color: rgb(128, 153, 207);
  width: 280px;
}

@media screen and (min-width: 550px) {
  #MenuSearchbar2 {
    display: none;
  }
}

.ClientSelection-items-container, .Organisation-items-container {
  margin-top: 10px;
  margin-left: -25px;
  margin-right: 25px;
}

.AdminMenu-items-container {
  margin-top: 15px;
}

.AdminMenu-item {
  text-decoration: none !important;
  color: black !important;
}

.btn-link-nopad {
  padding: 0px !important;
}

.ClientSelection-ClientLink, .Organisation-OrgLink {
  text-decoration: none !important;
  color: black !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  width: 300px !important;
  margin-left: 15px !important;
  margin-bottom: 20px !important;
}

.expiresCheckBoxLabel {
  margin-left: 5px;
  margin-right: 20px;
}

.checkBoxLabel {
  margin-left: 10px;
}

.lockGamesDiv {
  width: 300px;
}

.CreateClient {
  color: black;
  position:relative;
  float: right;
}

.CreateClient2 {
  color: black;
  margin-left: 20px;
}

.CreateClient3 {
  color: black !important;
  position:relative !important;
  float: right !important;
  bottom: 2px !important;
}

.AdminMenu-container {
  background-color: rgb(242, 242, 242);
  display:flex;
  height: calc(100vh - 80px);
}

.ProUsers-Menu-container, .Video-Menu-container {
  background-color: rgb(242, 242, 242);
  display:flex;
  height: calc(100vh - 120px);
}

.ClientSelection-Menu-container {
  background-color: rgb(242, 242, 242);
  display:flex;
  height: calc(100vh - 130px);
}

.Menu-container, .Exercises-Menu-container, .Organisation-Menu-container {
  background-color: rgb(242, 242, 242);
  display:flex;
  height: calc(100vh - 130px); /*100px*/
}

@media screen and (max-width: 550px) {
  .Exercises-Menu-container, .Organisation-Menu-container,
  .ClientSelection-Menu-container, .Video-Menu-container {
    flex-direction: column;
  }
  .ClientSelection-Menu-left {
    margin-bottom: 50px;
    width: 400px !important;
  }
  .Modules-Menu-left {
    margin-bottom: 50px;
  }
  .Organisation-Menu-left {
    margin-bottom: 50px;
    width: 420px !important;
  }
  .AddGame-Menu-left-footer, .Menu-left-footer, .Organisation-Menu-left-footer,
  .Recipes-Menu-left-footer, .Client-Menu-left-footer, .Progress-Menu-left-footer,
  .CreateClient-Menu-left-footer, .CreateOrganisation-Menu-left-footer,
  .ClientSelection-Menu-left-footer, .Templates-Menu-left-footer {
    display: none !important;
  }
  .clientFlexRow, .gameFlexRow {
    display: flex !important;
    flex-direction: column;
  }
  .ud2-row2-item1 {
    margin-left: 0px;
  }
  .pumrth-type {
    display: none !important;
  }
  .proUsersFlexRow {
    display: flex !important;
    flex-direction: column;
    border: solid 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 5px 10px 15px;
  }
  .Exercises-Menu-container, .Video-Menu-container, .Menu-container {
    height: unset !important;
  }
  .CrumbPathDiv {
    display: block !important;
  }
}

#sendNotificationCheckBox {
  margin-top: 20px;
}

.authEmailDiv {
  display: none;
}

.twoStepEmailRadioButtonGroup {
  margin-top: 15px;
}

.recipeStartDate {
  margin-right : 50px;
}

.notificationCheckBoxRow {
  width: 200px;
}

.accountTokenText {
  margin-right: 20px;
}

.Modules-Menu-left {
  padding: 0px 15px 0px 15px;
  margin-right: 20px;
  width: 400px;
}

.ClientSelection-Menu-left {
  padding: 0px 15px 0px 15px;
  margin-right: 20px;
  width: 550px;
  overflow-y: scroll;
  min-width: 350px;
  min-height: 300px;
}

.CrumbPathDiv {
  padding: 5px 10px 5px;
  display: inline;
}

.MainMenu-left, .MainMenu2-left, .ExerciseHistory-Menu-left  {
  padding: 20px 15px 0px 15px;
  margin-right: 20px;
  width: 400px;
}

.Menu-left {
  padding: 0px 15px 0px 15px;
  margin-right: 20px;
  width: 400px;
  height: calc(100vh - 130px); /*100px*/
}

.EditParameters-Menu-left {
  padding: 0px 15px 0px 15px;
  margin-right: 20px;
  width: 550px;
  height: calc(100vh - 130px); /*100px*/
  min-width: 350px;
}

.Organisation-Menu-left {
  padding: 0px 15px 0px 15px;
  margin-right: 20px;
  width: 550px;
  height: calc(100vh - 130px); /*100px*/
  overflow-y: scroll;
  min-width: 400px;
  min-height: 300px;
}

@media screen and (max-height: 350px) {
  .MainMenu-left, .MainMenu2-left {
    overflow: scroll;
  }
}

@media screen and (max-height: 400px) {
  .Modules-Menu-left {
    overflow: scroll;
    width: 550px;
  }
}

@media screen and (max-height: 550px) {
  .ExerciseHistory-Menu-left, .Organisation-Menu-left {
    overflow-y: scroll;
    width: 550px;
  }
}

@media screen and (max-width: 550px) {
  .Menu-left, .MainMenu2-left, .EditParameters-Menu-left {
    display: none;
  }
}

.linkButton {
  background-color: #1c87c9;
  border: none;
  color: white;
  padding: 20px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

.ExerciseHistory-Menu-left-footer, .NewRecipe-Menu-left-footer,
.AddGame-Menu-left-footer, .EditOrDeleteAccount-Menu-left-footer,
.ProUsers-Menu-left-footer, .AssignPros-Menu-left-footer,
.EditParameters-Menu-left-footer, .Recipes-Menu-left-footer,
.Templates-Menu-left-footer, .ResultByRecipe-Menu-left-footer,
.ResultByGame-Menu-left-footer {
  position: absolute;
  bottom: 20px;
  display:none;
}

.ClientSelection-Menu-left-footer {
  position: absolute;
  bottom: 13px;
  padding-bottom: 7px;
  display:none;
  background: rgba(242, 242, 242, 1);
}

.Menu-left-footer, .CreateClient-Menu-left-footer,
.Client-Menu-left-footer, .Progress-Menu-left-footer,
.CreateOrganisation-Menu-left-footer {
  position: absolute;
  bottom: 0px;
  display:none;
}

.Organisation-Menu-left-footer {
  position: absolute;
  bottom: 0px;
  padding-bottom: 5px;
  display:none;
  background: rgba(242, 242, 242, 1);
}

@-moz-document url-prefix() {
  .Client-Menu-left-footer, .Progress-Menu-left-footer,
  .CreateClient-Menu-left-footer, .CreateOrganisation-Menu-left-footer {
    position: absolute;
    bottom: 20px;
    display:none;
  }

  .Organisation-Menu-left-footer {
    position: absolute;
    bottom: 20px;
    padding-bottom: 5px;
    display:none;
    background: rgba(242, 242, 242, 1);
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  .CreateOrganisation-Menu-left-footer {
    position: absolute;
    bottom: 20px;
    display:none;
  }
}

@media screen and (min-height: 550px) {
  .Menu-left-footer, .ExerciseHistory-Menu-left-footer, .NewRecipe-Menu-left-footer,
  .AddGame-Menu-left-footer, .Organisation-Menu-left-footer,
  .EditOrDeleteAccount-Menu-left-footer, .CreateOrganisation-Menu-left-footer,
  .ProUsers-Menu-left-footer, .Recipes-Menu-left-footer, .Client-Menu-left-footer,
  .Progress-Menu-left-footer, .CreateClient-Menu-left-footer,
  .AssignPros-Menu-left-footer, .EditParameters-Menu-left-footer,
  .ClientSelection-Menu-left-footer, .Templates-Menu-left-footer,
  .ResultByRecipe-Menu-left-footer, .ResultByGame-Menu-left-footer {
    display: block;
  }
}

.addGameSpan {
  display: flex;
  width: 80px;
  margin-right: 60px;
}

.addGameSpan2 {
  margin-left: 35px;
}

.addGameCheckMark {
  margin-top: 5px;
  flex: 1 1 auto;
}

.mfad1-row1-item2 {
  padding-top: 10px;
  min-width: 180px;
  padding-left: 50px;
}

.videoText {
  white-space: pre-line;
}

.browseExerciseLabel {
  margin-top: 20px;
  margin-left: 10px;
}

.Menu-right, .Menu-right1, .Menu-right2, .Menu-right3, .Menu-right4 {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 5px;
  width:100%;
  padding-left: 20px;
}

@media screen and (max-height: 750px) {
  .Menu-right, .Menu-right1, .Menu-right2, .Menu-right3 {
    overflow: scroll;
  }
  .Organisation-Menu-left-footer {
    bottom: 20px;
  }
}

.Exercises-Menu-right, .Menu-right-newrecipe, .Video-Menu-right,
.AssignPros-Menu-right, .ProUsers-Menu-right , .Recipes-Menu-right,
.SearchResult-Menu-right, .Client-Menu-right, .ClientSelection-Menu-right {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 5px;
  width:100%;
  overflow: scroll;
  padding-left: 20px;
}

.EditParameters-Menu-right {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 5px;
  width:100%;
  overflow: scroll;
  padding-left: 20px;
  display: flex;
}

.EditParameters-Menu-right2 {
  margin-left: 30px;
}

.Menu-right2, .Menu-right3, .ClientSelection-Menu-right {
  display: none;
}

.Menu-right-top {
  margin-left: 20px;
  margin-top: 5px;
}

.recipesListDiv {
  border: solid 1px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 5px 10px 5px;
  margin-right: 30px;
}

.bottomBorderDiv {
  border-style: solid;
  border-bottom: none;
}

.CreateRecipes-menu-right-top2, .CreateRecipes-menu-right-top {
  margin-left: 20px;
}

.Menu-right-bottom-title {
  margin-top: 5x;
  margin-left: 20px;
}

.Clients-Menu-right-bottom, .CreateOrEditClient-Menu-right-bottom,
.Organisation-Menu-right-bottom, .Organisation-Menu-right-bottom2,
.EditOrDeleteAccount-Menu-right-bottom, .ProUser-Menu-right-bottom,
.AssignPros-Menu-right-bottom, .SearchResult-Menu-right-bottom {
  margin-top: 30px;
  margin-left: 20px;
}

.CreateOrganisationNameText, .CreateOrganisationUserCountText,
.OrganisationEndDateCalendar {
  margin-left: 20px;
}

.Recipes-Menu-right-bottom, .Progress-Menu-right-bottom,
.ProUsers-Menu-right-bottom, .ExerciseHistory-Menu-right-bottom,
.Modules-Menu-right-bottom {
  margin-left: 20px;
}

.Templates-Menu-right {
  margin-top: 15px;
}

.CreateRecipes-Menu-right-bottom {
  margin-left: 20px;
  margin-top: 20px;
  display:flex;
}

.CreateRecipes-Menu-right-bottom2 {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.EditParameters-Menu-right-bottom {
  margin-top: 20px;
  margin-bottom: 20px;
}

.chosenGamesNumber {
  margin-left: 50px;
  white-space: nowrap;
}

.CreateRecipeNameText, .reasonForVisitText {
  width: 300px;
}

.recipeExercisesLabel {
  margin-bottom: 10px;
}

.CreateRecipeNameLabel, .reasonForVisitLabel {
  padding-right: 20px;
}

.CreateRecipeStartYear, .CreateRecipeStartMonth, .CreateRecipeStartDay,
.CreateRecipeEndYear, .CreateRecipeEndMonth, .CreateRecipeEndDay {
  margin-top: 10px;
}

.CreateRecipeDateLine {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.CreateOrEditRecipeStartDateRow, CreateOrEditRecipeEndDateRow {
  display: flex;
}

.recipeNameTag {
  margin-right: 10px;
}

.timesPerDaySelect {
  width: 120px;
  margin-right: 10px;
}

.timesPerDayDiv {
  display: inline-block;
  position: relative;
  top: 5px;
}

.recycleBinDiv, .arrowDiv {
  display: inline-block;
}

.modules-exercise-link2 {
  display: block;
}

@media screen and (max-width: 866px) {
  .timesPerDayDiv {
    margin-left: 20px;
  }
}

.BrowseExerciseSpan, .exerciseButtonsSpan {
  position: relative;
  height: 100px;
}

.BrowseExerciseSpan {
  margin-right: 100px;
}

.Menu-right-top-header-link {
  text-decoration: none !important;
  padding-bottom: 1px !important;
}

.Menu-right-top-header-link2 {
  color: black !important;
  text-decoration: none !important;
}

.category-module {
  text-decoration: none !important;
}

.category-module:hover {
  color: rgb(0,0,205);
}

.menu-header-link-selected {
  color: var(--bs-link-color) !important;
}

.Menu-right-top-table, .Menu-right-top-table2, .Menu-right-top-table3,
.Menu-right-top-table-row2, .ExerciseHistory-Menu-left-table,
.Menu-right-bottom-table-lower, .Menu-right-bottom-table-upper,
.Modules-Menu-left-table, .CreateOrEditRecipeMenu-left,
.CreateOrEditRecipeMenu-right, .AddGameTable, .resultsTable {
  border-collapse: collapse;
  width: 100%;
}

.resultsTable {
  margin-bottom: 20px;
  margin-right: 20px;
  border: 1px solid;
}

.resultsDateRow {
  white-space: nowrap;
  padding: 5px;
  padding-right: 10px;
  border: 1px solid;
}

.resultsRow {
  border: 1px solid;
  padding: 5px;
}

.resultsRowClick {
  border: 1px solid;
  padding: 5px;
  text-decoration: underline;
}

.resultsRowClick:hover {
  color: rgb(0,0,205);
}

.resultsChart {
  display: none;
}

.Menu-right-top-table5 {
  margin-bottom: 15px;
}

.ehmrth-set-part2, .csmrth-confirm {
  padding-left: 30px;
}

.ehd1-row1-item1, .ehd1-row2-item1, .ehd1-row3-item1, .ehd1-row4-item1 {
  padding-left: 20px;
  padding-top: 10px;
}

.ehd1-row1-item2, .ehd1-row2-item2, .ehd1-row3-item2, .ehd1-row4-item2 {
  padding-top: 12px;
}

.od1-row1-item2, .od2-row1-item2 {
 position: relative;
 top: 10px;
}

.old1-row2, .old1-row3, .old1-row4, .old1-row5, .old1-row6 .old1-row7,
.pud1-row1, .pud1-row2, .pud1-row3, .pud1-row4, .pud1-row5, .pud1-row6, .pud1-row7,
.pud1-row8 {
  position: relative;
}

.old1-row2-item1, .old1-row2-item2, .old1-row2-item3, .old1-row2-item4,
.old1-row3-item1, .old1-row3-item2,
.old1-row4-item1, .old1-row4-item2, .old1-row4-item3,
.old1-row5-item1, .old1-row5-item2, .old1-row5-item3,
.old1-row6-item1, .old1-row6-item2, .old1-row6-item3,
.old1-row7-item1, .old1-row7-item2 {
  padding-top: 10px;
}

.pud1-row1-item1, .pud1-row1-item2, .pud1-row1-item3,
.pud1-row3-item1, .pud1-row3-item2, .pud1-row3-item3,
.pud1-row4-item1, .pud1-row4-item2, .pud1-row4-item3,
.pud1-row5-item1, .pud1-row5-item2, .pud1-row5-item3,
.pud1-row6-item1, .pud1-row6-item2, .pud1-row6-item3,
.pud1-row7-item1, .pud1-row7-item2, .pud1-row7-item3,
.pud1-row8-item1, .pud1-row8-item2, .pud1-row8-item3 {
  padding-top: 15px;
}

.ehd2-row2-item1, .ehd2-row2-item2, .ehd2-row2-item3,
.ehd2-row3-item1, .ehd2-row3-item2, .ehd2-row3-item3,
.ehd2-row4-item1, .ehd2-row4-item2, .ehd2-row4-item3,
.ehd2-row5-item1, .ehd2-row5-item2, .ehd2-row5-item3,
.ehd2-row6-item1, .ehd2-row6-item2, .ehd2-row6-item3,
.ehd2-row7-item1, .ehd2-row7-item2, .ehd2-row7-item3,
.ehd2-row8-item1, .ehd2-row8-item2, .ehd2-row8-item3 {
  padding-top: 10px;
}

.mfad1-row1-item4 {
  padding-top: 10px;
}

.mfad1-row1-item3 {
  padding-top: 10px;
  padding-right: 80px;
}

.ClientSelection-clientname {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 200px !important;
  max-width: 200px !important;
}

.languageHeader {
  margin-top: 20px;
  margin-left: 10px;
}

.eulaText {
  background-color: lightgrey;
  width: 100%;
  height: 50vh;
}

.eulaText2 {
  height: 80vh;
  width: 100%;
  margin-top: 30px;
  margin-left: 10px;
}

.eulaHeader {
  position: relative;
  top: 20px;
  margin-left: 10px;
}

.eulaImage, .copyrightImage {
  margin-bottom: 20px;
}

.react-confirm-alert:has(.eula-custom-ui) {
  width: 90%;
}

.react-confirm-alert:has(.login-custom-ui) {
  margin-left: 50px;
}

.react-confirm-alert:has(.removeTwoStep-custom-ui) {
  margin-left: 50px;
}

.react-confirm-alert:has(.enableTwoStep-custom-ui) {
  margin-left: 50px;
}

.react-confirm-alert:has(.info-custom-ui) {
  margin-left: 50px;
}

.videoText {
  width: 60vh;
  height: 400px;
}

.OrganisationTypeLabel, .OrganisationUserCountLabel, .OrganisationExpireDateLabel {
  margin-top: 15px;
}

.OrganisationLicence-Edit-link {
  position: relative;
  top: 7px;
  color: black;
}

.Menu-right-bottom-table-lower {
  height: 150%;
  padding: 50px;
  margin-bottom: 50px;
}

.coerd-newrecipe-table {
  margin-top: 50px;
}

.Menu-right-top-table2 {
  margin-top: 20px;
  margin-left: 150px;
}

.Menu-right-top-table3 {
  margin-top: 5px;
  margin-left: 150px;
}

.CreateOrEditRecipeMenu-right {
  margin-left: 20px;
}

.modules-settings-header {
  width: 23%;
}

.Clients-Menu-right-bottom-header1 {
  width: 60%;
}

.Clients-Menu-right-bottom-header2 {
  width: 15%;
}

.coepud1-row1-item1 {
  width: 25%;
}

.Menu-right-top-createnewrecipe, .Menu-right-top-header-link, .cnmrth-confirm,
.Edit-link, .Site-link, .category-module, .modules-settings-link, .ud2-row2-item2,
.videoSettingsLink, .editClientLink, .deleteClientLink, .editOrganisationLink,
.searchForProUsersLink, .assignProsLink, .searchForClientsLink {
  color: black !important;
}

.searchResultsLink, .releaseDeviceLockLink {
  color: black !important;
  white-space: nowrap;
}

.recipeEditLink, .recipeShowLink {
  color: black !important;
  margin-right: 30px;
}

.Edit-exercises-link, .Edit-exercises-link:hover,
.Modules-edit-link, .License-manager-link, .Expired-ClientLink,
.Expired-UserLink, .Expired-Recipe {
  color: gray !important;
}

.recipeParameterEditLink {
  color: black !important;
  margin-left: 20px;
}

.pud1-row1:hover, .pud1-row1 button:hover {
  color: green !important;
}

.editProUserLink, .deleteProUserLink {
  color: black !important;
  position: relative !important;
  top: 6px !important;
}

.slider {
  width: 350px;
  margin-top: 10px;
}

.nameLabel2 {
  float: right;
  top: 2px;
  position: relative;
}

.nameHeader {
  margin-top: 10px;
}

.nameHeader2 {
  margin-top: 10px;
  font-weight: bold;
}

.radioButtonGroupHeader {
  margin-bottom: 5px;
}

.EditParameters_select {
  margin-bottom: 10px;
  width: 350px;
}

.settings-container {
  width: 350px;
}

.EditParameters-info-icon, .stimuliNumberOptions {
  display: flex;
}

.radioButtonLabel {
  margin-left: 10px;
}

.coecd4-row1-item8 {
  width: 230px;
}

.coecd1-row1-item1 {
  width: 25%;
}

.lockGames-row {
  position: relative;
  top: 20px;
}

.stimuliRadioButtonGroup, .cellSizeRadioButtonGroup, .layoutRadioButtonGroup,
.advancedRadioButtonGroup, .letterSizeRadioButtonGroup,
.stringDirectionRadioButtonGroup, .interferenceTypeRadioButtonGroup,
.stringDirectionRadioButtonGroup2, .serieRecoveryRadioButtonGroup,
.nBackRadioButtonGroup, .presentationTypeRadioButtonGroup,
.returnTypeRadioButtonGroup, .fishScaleRadioButtonGroup,
.exerciseTypeRadioButtonGroup, .bowlScaleRadioButtonGroup,
.lineTypeRadioButtonGroup, .imageTypeRadioButtonGroup, .pointSizeRadioButtonGroup,
.figureTypeRadioButtonGroup, .stimulusVisibilityRadioButtonGroup,
.quadrantTransportRadioButtonGroup, .difficultyRadioButtonGroup2,
.targetPositionRadioButtonGroup, .difficultyRadioButtonGroup,
.exerciseStartedByRadioButtonGroup, .boardSizeRadioButtonGroup,
.blackKnightsRadioButtonGroup, .interferenceTypeRadioButtonGroup2,
.stimulusTypeRadioButtonGroup, .difficultyRadioButtonGroup4,
.difficultyRadioButtonGroup5, .boardSizeRadioButtonGroup2 {
  border: 1px solid;
  padding: 5px;
  margin-top: 10px;
}

.soundFeedBackOptions, .allowRepeatOptions, .useOutlinesOptions, .instantFeedbackOptions,
.stimuliRandomOptions {
  margin-top: 10px;
}

.fieldShapeDiv {
  width: 550px;
  height: 370px;
}

.fieldShapeRadioButtonGroup {
  border: 1px solid;
  border-bottom: none;
  padding: 5px;
  margin-top: 10px;
  width: 520px;
  height: 40px;
  float:left;
}

.fieldShapeRadioButton2Group {
  border: 1px solid;
  border-top: none;
  border-right: none;
  padding: 5px;
  width: 70px;
  height: 300px;
  float: left;
}

.fieldShapeRow1, .fieldShapeRow2 {
  height: 100px;
}

.fieldShapeRadioButton3Group {
  border: 1px solid;
  border-bottom: none;
  border-top: none;
  border-left: none;
  padding: 5px;
  width: 450px;
  height: 100px;
  float: left;
}

.fieldShapeRadioButton4Group {
  border: 1px solid;
  border-bottom: none;
  border-top: none;
  border-left: none;
  padding: 5px;
  width: 450px;
  height: 100px;
  float: left;
}

.fieldShapeRadioButton5Group {
  border: 1px solid;
  border-top: none;
  border-left: none;
  padding: 5px;
  width: 450px;
  height: 100px;
  float: left;
  margin-bottom: 50px;
}

.difficultyRadioButtonGroup3 {
  border: 1px solid;
  padding: 5px;
  margin-top: 10px;
  width: 400px;
}

.rectangleNarrow, .circleNarrow, .diamondNarrow {
  margin-right: 10px;
  margin-left: 10px;
}

.rectangleWide {
  margin-left: 20px;
}

.circleWide {
  margin-left: 6px;
}

.diamondWide {
  margin-left: 5px;
}

#advancedFishLabel, #advancedFlagsLabel,
#difficultyA1Label, #difficultyA2Label, #difficultyB1Label,
#difficultyB2Label {
  width: 60px;
}

#professionsCheckBoxLabel, #animalsCheckBoxLabel, #plantsCheckBoxLabel,
#citiesCheckBoxLabel, #maleNamesCheckBoxLabel, #additionCheckBoxLabel,
#multiplicationCheckBoxLabel, #professionsCheckBoxLabel2, #plantsCheckBoxLabel2,
#foodStuffCheckBoxLabel, #vehiclesCheckBoxLabel, #figureGeometricLabel,
#figureNumberLabel, #positionLeftLabel, #positionMiddleLabel,
#interferenceMathLabel {
  width: 100px;
}

.difficultyRow1Options, .difficultyRow2Options {
  display: flex;
}

.difficultyLevel1Options, .difficultyLevel2Options {
  width: 200px;
}

.modules-exercise-link-selected, .modules-settings-link-selected {
  color: green !important;
}

.Account-edit-link {
  color: green !important;
  margin-bottom: 15px !important;
}

.ParametersEdited-Link {
  color: green !important;
}

.Account-delete-link {
  color: red !important;
}

.TwoFactorDeleteLink {
  color: red !important;
}

.accountUserTitle {
  margin-top: 20px;
}

.AssignProUser-link1 {
  padding-right: 30px !important;
}

.CreateOrEditClient-Menu-right-bottom-table-upper  {
  margin-left: 300px;
  margin-top: 20px;
}

.ehmrth-number, .ehd2-row1-item1 {
  width: 50px;
}

.ehmrth-set, .ehd2-row1-item2 {
  width: 55%;
}

.cmrth-client, .cnmrth-client {
  width: 35%;
}

.pud1-row1-item2 {
  /*width: 34%;*/
  width: 20%;
}

.pud1-row1-item3 {
  /*width: 13%;*/

}

.pud1-row1-item4 {
  /*width: 10%;*/
}

.pud1-row1-item5 {
  /*width: 10%;*/
  width: 46%;
}

.cnmrth-recipes, .cnmrth-licences, .cnmrth-general, /*.cnmrth-progress*/
.cnemrth-condition, .reciperow,
.omrth-general, .omrth-subscription, .pumrth-site, .coerd-row2-item1 {
  width: 20%;
}

.cnemrth-type {
  width: 30%;
}

.cnemrth-ctscore {
  width: 22%;
}

.pumrth-type {
  width: 20%;
}

.cnemrth-status {
  width: 35 %;
}

.coerd-row4-item1 {
  width: 13%;
}

.coerd-row5-item1 {
   width: 13%;
}

.coerd-row6-item1 {
  width: 8%;
}

.cnemrth-history, .coerd-row1-item1 {
  width: 25%;
}

.cnmrth-progress {
  width: 60%;
}

.recipetextrow {
  width: 100%;
}

td, th {
  text-align: left;
}

input[type="text"] {
  width: 280px;
}

.Menu-line {
  content: "";
  display: inline-block;
  border-top: 1px solid #000;
  width: 100%;
  vertical-align: middle;
  margin-left: 15px;
}

.Menu-line2 {
  content: "";
  display: inline-block;
  border-top: 1px solid #000;
  width: 100%;
  vertical-align: middle;
}

.MainMenu-line {
  content: "";
  display: inline-block;
  border-top: 1px solid #000;
  width: 100%;
  vertical-align: middle;
  margin-top: 40px;
}

.ProUsers-Menu-line, .ExerciseHistory-Menu-line {
  content: "";
  display: inline-block;
  border-top: 1px solid #000;
  width: 100%;
  vertical-align: middle;
  margin-left: 0px;
}

.ProUsers-Menu-line2 {
  content: "";
  display: inline-block;
  border-top: 1px solid #000;
  width: 100%;
  vertical-align: middle;
  margin-left: -20px;
}

.Modules-right-menu-header {
  padding-left: 150px;
  display: inline-block;
}

.ClientSelection-right-menu-header {
  padding-left: 400px;
  display: inline-block;
}

@media screen and (max-width: 920px) {
  .ClientSelection-right-menu-header {
    padding-left: 100px;
  }
}

.ClientSelection-line, .Modules-line {
  content: "";
  display: inline-block;
  border-top: 1px solid #000;
  width: 100%;
  vertical-align: middle;
}

.blue-highlight {
  color: royalblue !important;
  font-weight: bold !important;
}
